export const appParam = {
  kx: {
    path: 'terminal',
    name: 'terminal',
    entry: '/child/kuaixiao/',
  },
  bd: {
    path: 'operating',
    name: 'operating',
    entry: '/child/biding/',
  },
  bh: {
    path: 'buhuo',
    name: 'buhuo',
    entry: '/child/bh/',
  },
  mall: {
    path: 'mall',
    name: 'mall',
    entry: '/child/bdmall/',
  },
  datacenter: {
    path: 'datacenter',
    name: 'datacenter',
    entry: '/child/dc/',
  },
  sp: {
    path: 'supply',
    name: 'supply',
    entry: '/child/supplier/',
  },
  pos: {
    path:'pos',
    name: 'pos',
    entry: '/child/pos/',
    
  }
};